import './DeviceView.css'
import SettingsValue from './SettingsValue.js'
import EditableText from './EditableText.js'
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef, useContext, createContext } from 'react';
import checkimg from './check.png'
import ximg from './x.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import * as Validators from './Validators.js'
import SettingsPage from './SettingsPage.js'
import ReadingsPage from './ReadingsPage.js'
import ErrorsPage from './ErrorsPage.js'
import {settingsObj} from './Settings.js';


export const DeviceSettingsContext = createContext(settingsObj);
export const ServerSettingsContext = createContext(settingsObj);
export const ErrorsContext = createContext([]);


function DeviceView() {
    const { uid } = useParams();

    const [Device_LastContact, setDevice_LastContact] = useState('');
    const [Device_Updated, setDevice_Updated] = useState(false);

    const [deviceSettings, setDeviceSettings] = useState(settingsObj);
    const [serverSettings, setServerSettings] = useState(settingsObj);
    const [origServerSettings, setOrigServerSettings] = useState(settingsObj);
    const [errors, setErrors] = useState([]);




    // On load, pull data from server
    useEffect(() => {
        refreshData(); 
    }, [uid]);


    async function refreshData() {
        try {
            let url = window.location.origin + '/api/device/' + uid;
            let response = await fetch(url);
            let data = await response.json();
            console.log("refreshing data");
            console.log(data);

            let datetime = new Date(data.last_contact);
            const today = new Date()
            if(datetime.getFullYear() == today.getFullYear() &&
                datetime.getMonth() == today.getMonth() &&
                datetime.getDay() == today.getDay()) {
                setDevice_LastContact(datetime.toLocaleTimeString('en-US'));
            }
            else {
                setDevice_LastContact(datetime.toLocaleString('en-US'));
            }

            setDevice_Updated(data.updated);

            setDeviceSettings(data.device_settings);
            setServerSettings(data.server_settings);
            setOrigServerSettings(data.server_settings);
        } catch(error) {
            console.log(error);
        }

        try {
            let url = window.location.origin + '/api/device/' + uid + '/errors';
            let response = await fetch(url);
            let data = await response.json();
            console.log(data);

            setErrors(data.errors);
        } catch(error) {
            console.log(error);
        }
    }

    return (
        <DeviceSettingsContext.Provider value={{deviceSettings, setDeviceSettings}}>
        <ServerSettingsContext.Provider value={{serverSettings, setServerSettings}}>
        <ErrorsContext.Provider value={errors}>
        <div>
            <button onClick={refreshData}>Refresh Data</button>
            <div className="NameDisplayContainer">
                <div className="NameDisplay">
                    <h1 className="NameDisplayText">{deviceSettings.name}</h1>
                </div>

                {uid.length > 0 && 
                    <div>UID: {uid}</div>
                }
            </div>


            <Tabs>
                <TabList>
                    <Tab>Home</Tab>
                    <Tab>Settings</Tab>
                    <Tab>Sensor Readings</Tab>
                    <Tab>Errors</Tab>
                </TabList>

                <TabPanel>
                    <h2>Home Page</h2>
                </TabPanel>
                <TabPanel>
                    <SettingsPage refreshData={refreshData}
                        Device_Updated={Device_Updated} Device_LastContact={Device_LastContact}/>
                </TabPanel>
                <TabPanel>
                    <ReadingsPage/>
                </TabPanel>
                <TabPanel>
                    <ErrorsPage/>
                </TabPanel>
            </Tabs>
        </div>
        </ErrorsContext.Provider>
        </ServerSettingsContext.Provider>
        </DeviceSettingsContext.Provider>
    );
}


export default DeviceView;
