import './ErrorsPage.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect, useRef, useContext, createContext } from 'react';
import {ErrorsContext} from './DeviceView.js';


export default function ErrorsPage({refreshData, Device_Updated, Device_LastContact}) {
    const { uid } = useParams();
    const errors = useContext(ErrorsContext);

    const error_items = errors.map(error =>
        <li key={error.received_timestamp}>
            <h3>{error.received_timestamp}</h3>
            <p className="errorText">{error.error}</p>
        </li>
    );


    return (
        <div>
            <ul className="Error-list">{error_items}</ul>
        </div>
    );
}
